import React from 'react';
import Card from 'react-bootstrap/Card';

import './Contact.css';

const Contact = () => {
  return (
    <>
      {/* Jumbotron Replacement */}
      <div className="p-5 mb-4 bg-light border rounded-3 pageHeader">
        <div className="container-fluid py-2">
          <h1>CONTACT ME</h1>
        </div>
      </div>

      <Card className='contactCard'>
        <Card.Header
        >Contact Information</Card.Header>
        <Card.Body>
          <Card.Text className='contactInfo'>
            <p>If you would like to reach me regarding any opportunities or inquiries, feel free to contact me using the information below. Thank you!</p>
            <ul>
              <li>Email: KiernanBrownGames@gmail.com</li>
              <li>Phone: 732-470-4234</li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Contact;









