import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToAnchor from './components/ScrollToAnchor';

import Navigation from './components/Navigation';
import Home from './pages/Home';
import Projects from './pages/Projects';
import About from './pages/About';
import Skills from './pages/Skills';
import Contact from './pages/Contact';

import './App.css';

function App() {
  return (
    <Router>
      <Navigation />
      <main>
        <Routes>
        <Route path='/home' exact element={<Home />} />
        <Route path='/projects' exact element={<Projects />} />
        <Route path='/about' exact element={<About />} />
        <Route path='/skills' exact element={<Skills />} />
        <Route path='/contact' exact element={<Contact />} />
        <Route path="*" element={<Navigate replace to="/home" />} />
        </Routes>
      </main>
      <ScrollToAnchor />
    </Router>
  );
}

export default App;
